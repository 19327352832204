<template>
  <div class="dashboard-lab-orders">
    <HeroImage :bg-image="'images/2-service-pages-hero.jpg'" :has-purple-bg="false">
    </HeroImage>
    <section class="over-hero">
      <div class="container is-fullhd">
        <div class="my-account-wrap">
          <div class="columns content-my-account-wrap">
            <lab-menu></lab-menu>
            <!-- ORDER LIST -->
            <div class="column is-12 right-column" v-if="!isPickupConfirmation">
              <div class="columns order-state-filter-wrap">
                <button class="column order-state-filter pendiente-lab" @click="setStateFilter('pendiente-lab')" v-bind:class="{ 'selected': selectedStateFilter === 'pendiente-lab' }">
                  <span class="status-circle"></span>
                  PENDIENTE LABORATORIO
                </button>
                <button class="column order-state-filter en-transito" @click="setStateFilter('en-transito')" v-bind:class="{ 'selected': selectedStateFilter === 'en-transito' }">
                  <span class="status-circle"></span>
                  EN TRANSITO
                </button>
                <button class="column order-state-filter pendiente-clinic" @click="setStateFilter('pendiente-clinic')" v-bind:class="{ 'selected': selectedStateFilter === 'pendiente-clinic' }">
                  <span class="status-circle"></span>
                  PENDIENTE CLINICA
                </button>
                <button class="column order-state-filter completed" @click="setStateFilter('completed')" v-bind:class="{ 'selected': selectedStateFilter === 'completed' }">
                  <span class="status-circle"></span>
                  COMPLETADO
                </button>
              </div>

              <hr class="mb-5">

              <div class="search-filter-wrap">
                <div class="search-wrap">
                  <div class="control has-icons-right">
                    <input class="input is-rounded" type="text" placeholder="Busca un producto..." v-model="searchText" @input="searchProduct()">
                    <span class="icon is-right has-text-centered" @click="searchProduct()">
                      <span class="icon-search"></span>
                    </span>
                  </div>
                </div>
                <div class="filter-wrap">
                  <div class="order-wrap">
                    <span class="icon-decrescente" v-bind:class="{'selected': orderList === 'DESC'}" @click="setOrderList('DESC')"></span>
                    <span class="icon-crescente" v-bind:class="{'selected': orderList === 'ASC'}" @click="setOrderList('ASC')"></span>
                    <div class="field">
                      <div class="select is-primary is-full-width">
                        <select v-model="filterSelected" @change="setListOrderFilter()">
                          <option :value="'created_at'">Fecha de pedido</option>
                          <option :value="'updated_at'">Ultima actualización</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="search-filter-wrap">
                <div class="search-wrap">
                  <div class="search-results">
                    <p>
                      <span v-if="orders.length > 0" class="has-text-weight-bold">{{orders.length}}</span> resultados incontrados
                    </p>
                  </div>
                </div>
                <div class="filter-wrap">
                  <button v-if="!isPickupModeOn" @click="openPickupMode()"><u>Solicitar una recogida</u> <span class="icon-pickup-all pl-4"></span></button>
                  <button class="close-pick-up" v-if="isPickupModeOn" @click="closePickupMode()"><u>Solicitar una recogida</u> <span class="icon-pickup-all pl-4"></span></button>
                </div>
              </div>

              <div class="order-group-wrap">
                <div class="table-header mt-4">
                  <div class="cell-small"></div>
                  <div class="cell-medium">Pedido no.</div>
                  <div class="cell-large-percentage">Información</div>
                  <div class="cell-large">Paziente</div>
                  <div class="cell-large">Laboratorio</div>
                  <div class="cell-medium">Expediente no.</div>
                  <div class="cell-small">Precio</div>
                </div>
                <div v-if="!isLoading && orders.length === 0">
                  <h3 class="title is-4 txt-c-mobile pt-4">
                    No pedidos availables
                  </h3>
                </div>
                <template v-for="order in orders">
                  <order-card
                    v-bind:key="order.id"
                    :order="order"
                    :selected-state-filter="selectedStateFilter"
                    :is-pickup-mode-on="isPickupModeOn"
                    v-on:set-checkbox-value="setCheckboxValue"
                    v-on:open-pickup-mode="openPickupMode"></order-card>
                </template>
              </div>

              <hr class="mt-3">

              <div class="page-navigation-wrap is-flex">
                <div class="page-navigation columns">
                  <button class="column selected">1</button>
                  <button class="column">2</button>
                  <button class="column">3</button>
                  <button class="column">4</button>
                  <button class="column">5</button>
                </div>
              </div>
            </div>
            <!-- PICK UP ORDER LIST CONFIRMATION -->
            <div class="column is-8 right-column" v-if="isPickupConfirmation">
              <h3 class="title is-4 txt-c-mobile mb-5">
                Reservar recogida
                <button @click="closePickupConfirmation()" class="multi-select-actions-cancel">
                  Cancelar y volver a todos los pedidos
                </button>
              </h3>
              <div class="order-group-wrap">
                <div v-if="ordersSelected.length === 0">
                  <h3 class="title is-4 txt-c-mobile">
                    No pedidos availables
                  </h3>
                </div>
                <template v-for="order in ordersSelected">
                  <order-card
                    v-bind:key="order.id"
                    :order="order"
                    :selected-state-filter="selectedStateFilter"
                    :is-pickup-mode-on="isPickupModeOn"
                    v-on:set-checkbox-value="setCheckboxValue"
                    v-on:open-pickup-mode="openPickupMode"
                    :show-buttons="false"></order-card>
                </template>
                <hr class="mt-3 mb-5">
                <div class="columns mb-5">
                  <div class="column is-8">
                    <p class="has-text-weight-bold txt-yellow is-uppercase">Gastos de envío</p>
                    <p>
                      Los gastos de envío se facturarán automáticamente a la forma de pago predeterminada
                    </p>
                  </div>
                  <div class="column is-4">
                    <p class="has-text-weight-bold has-text-right is-size-4">5 €</p>
                  </div>
                </div>
                <div id="locations" class="mb-6">
                  <div class="columns is-variable is-3 is-flex-wrap-wrap">
                    <div class="column is-6 mb-1">
                      <div class="has-background-light location-box">
                        <p class="pb-2"><strong class="txt-yellow is-uppercase">Dirección de recogida</strong></p>
                        <p>
                          <strong>{{laboratory.pickup_location.address.name}}</strong><br>
                          {{laboratory.pickup_location.address.address1}}<br>
                          {{laboratory.pickup_location.address.zip}} {{laboratory.pickup_location.address.city}} ({{laboratory.pickup_location.address.province}}), {{laboratory.pickup_location.address.country}}<br>
                          {{laboratory.pickup_location.address.phone}}<br>
                        </p>
                      </div>
                    </div>
                    <div class="column is-6 mb-1">
                      <div class="has-background-light location-box">
                        <p class="pb-2"><strong class="txt-yellow is-uppercase">Dirección de envío</strong></p>
                        <p>
                          <strong>?? Nome clinica</strong><br>
                          ?? Indirizzo clinica<br>
                          ?? zip, città, Provincia e country<br>
                          ?? Telefono clinica<br>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <button class="button is-medium is-fullwidth is-primary is-uppercase" @click="createPickup()">Confirmar recogida</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
    <div v-if="isPickupModeOn" class="multi-select-wrapper">
      <div class="multi-select-info">
        <span class="icon icon-check-green"></span>
        <p>You’ve selected <span>{{ordersSelected.length}}</span> items ready for pickup</p>
      </div>
      <div class="multi-select-actions-wrap">
        <button @click="closePickupMode()" class="multi-select-actions-cancel">
          Cancelar y volver a todos los pedidos
        </button>
        <button class="multi-select-actions-confirm" @click="confirmPickup()">
          CONFIRM PICKUP
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HeroImage from '@/components/HeroImage.vue'
import Footer from '@/components/Footer.vue'
import LabMenu from '@/components/Laboratory/LabMenu.vue'
import OrderCard from '@/components/Clinic/OrderCard.vue'

export default {
  name: 'LaboratoryLabOrdersNew',
  components: {
    HeroImage,
    Footer,
    LabMenu,
    OrderCard
  },
  data () {
    return {
      orders: [],
      ordersSelected: [],
      selectedStateFilter: 'pendiente-lab',
      isPickupModeOn: false,
      isLoading: true,
      isPickupConfirmation: false,
      searchText: '',
      searchTimeout: null,

      statusIn: '',
      filterSelected: 'created_at',
      orderList: 'DESC'
    }
  },
  computed: {
    ...mapGetters({
      laboratory: 'user/laboratory'
    })
  },
  methods: {
    setListOrderFilter () {
      this.setStateFilter(this.selectedStateFilter)
    },
    setOrderList (orderList) {
      this.orderList = orderList
      this.setStateFilter(this.selectedStateFilter)
    },
    searchProduct () {
      const self = this
      clearTimeout(this.searchTimeout)
      if (this.searchText.trim().length > 0) {
        this.searchTimeout = setTimeout(() => {
          self.getLabOrders()
        }, 500)
      } else {
        self.getLabOrders()
      }
    },
    setCheckboxValue (order) {
      if (order.selected) {
        this.ordersSelected.push(order)
      } else {
        const searchIndex = this.ordersSelected.findIndex((o) => o.id === order.id)
        if (searchIndex !== -1) {
          this.ordersSelected.splice(searchIndex, 1)
        }
      }
    },
    openPickupMode (order) {
      this.isPickupModeOn = true
      this.setStateFilter('pendiente-lab', order)
    },
    closePickupMode (noRefresh = false) {
      this.isPickupModeOn = false
      this.isPickupConfirmation = false
      if (noRefresh) {
        for (let i = 0; i < this.orders.length; i++) {
          this.orders[i].selected = false
        }
      }
      this.ordersSelected = []
      if (!noRefresh) {
        this.setStateFilter(this.selectedStateFilter)
      }
    },
    createPickup () {
      const ordersId = []

      for (let i = 0; i < this.ordersSelected.length; i++) {
        ordersId.push(this.ordersSelected[i].id)
      }

      const payload = {
        labId: this.laboratory.id,
        ordersId: ordersId,
        cb: (response) => {
          console.log(response)
          this.closePickupMode()
          this.setStateFilter('pendiente-lab')
          this.goTo('/laboratory/orders/test/completed')
        },
        cbError: (error) => {
          this.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error.response.data.error
            }
          })
        }
      }

      this.$store.dispatch('lab/createPickUpRequest', payload)
    },
    confirmPickup () {
      this.isPickupModeOn = false
      this.isPickupConfirmation = true
      window.scrollTo(0, 0)
      // Get Shipping location
      /* this.$store.dispatch('lab/getLocationById', {
        locationId: this.ordersSelected[0].shipping_address_id,
        cb: (response) => {
          console.log('Get Shipping location')
          console.log(response)
        },
        cbError: () => {
          this.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: 'Error on get the shipping location'
            }
          })
        }
      }) */
    },
    closePickupConfirmation () {
      this.isPickupConfirmation = false
      this.isPickupModeOn = true
      window.scrollTo(0, 0)
    },
    setStateFilter (value, order = null) {
      this.selectedStateFilter = value
      if (this.selectedStateFilter === 'en-transito') {
        this.statusIn = 'shipped_to_laboratory, shipped_to_clinic'
        this.getLabOrders(order)
      }
      if (this.selectedStateFilter === 'pendiente-lab') {
        this.statusIn = 'created,started,returned'
        this.getLabOrders(order)
      }
      if (this.selectedStateFilter === 'pendiente-clinic') {
        this.closePickupMode(true)
        this.statusIn = 'delivered'
        this.getLabOrders(order)
      }
      if (this.selectedStateFilter === 'completed') {
        this.closePickupMode(true)
        this.statusIn = 'archived,cancelled'
        this.getLabOrders(order)
      }
    },
    logout () {
      const self = this
      this.$store.dispatch('user/logOut', {
        cb: () => {
          self.goTo('/')
        },
        cbError: () => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: 'Error on the log out'
            }
          })
        }
      })
    },
    goTo (path) {
      this.$router.push({ path: path })
    },
    getLabOrders (order = null) {
      const self = this
      this.isLoading = true
      const payload = {
        labId: this.laboratory.id,
        statusIn: this.statusIn,
        filterSelected: this.filterSelected,
        orderList: this.orderList,
        query: this.searchText,
        page: 1,
        pageSize: 10,
        cb: (response) => {
          console.log(response)
          self.orders = []
          for (let i = 0; i < response.length; i++) {
            response[i].selected = false
            if (order !== null) {
              if (response[i].id === order.id) {
                response[i].selected = true
                this.ordersSelected.push(response[i])
              }
            }
          }
          self.orders = response
          self.isLoading = false
          self.searchIsLoading = false
        },
        cbError: (error) => {
          self.isLoading = false
          self.searchIsLoading = false
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error.response.data.error
            }
          })
        }
      }
      if (order !== null) {
        payload.clinicIdIn = order.clinic_id
      }
      // statusIn
      // clinicIdIn
      // stati created started shipped delivered returned archived cancelled
      // pendiente clinica --> delivered, shipped
      // pendiente laboratorio --> created started returned shipped_to_lab
      // completed --> archived cancelled
      // pickup order usare sempre --> started created -> a shipped
      // per clinica lab -->
      this.$store.dispatch('lab/getLabOrders', payload)
    }
  },
  created () {
    this.setStateFilter('pendiente-lab')
  }
}
</script>

<style scoped lang="scss">
.dashboard-lab-orders{
  width: 100%;

  .multi-select-wrapper{
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 18px;
    left: 13px;
    z-index: 99999999999999;
    height: 102px;
    width: calc(100% - 26px);
    border-radius: 10px;
    background-color: rgba(87,86,124,1);
    box-shadow: 0 2px 24px 0 rgba(0,0,0,0.5);
    .multi-select-info{
      display: flex;
      padding: 0 28px;
      .icon{
        align-self: center;
        font-size: 1.6rem;
        margin-right: 28px;
      }
      p{
        color: #FFF;
        align-self: center;
        font-weight: bold;
        font-size: 1.2rem;
        span{
          color: #94C38E;
        }
      }
    }
    .multi-select-actions-wrap{
      display: flex;
      padding: 0 28px;
      .multi-select-actions-cancel{
        align-self: center;
        text-decoration: underline;
        cursor: pointer;
        background-color: transparent;
        border: 0px;
        margin-right: 28px;
        color: #FFF;
      }
      .multi-select-actions-confirm{
        align-self: center;
        height: 50px;
        width: 328px;
        border-radius: 4px;
        background-color: #94C38E;
        border: 0px;
        color: #FFF;
        cursor: pointer;
      }
    }
  }

  section{
    width: 100%;
    padding: 0px;
    &.over-hero{
      position: relative;
      margin-top: -430px;
      margin-bottom: 80px;
      z-index: 100;
    }
    .my-account-wrap{
      width: 100%;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
      .content-my-account-wrap{
        padding: 0px;
        flex-direction: column;
        .has-background-light{
          border-radius: 4px 0px 0px 4px;
        }
        .right-column{
          padding: 20px 80px;
          .title{
            position: relative;
            .multi-select-actions-cancel{
              position: absolute;
              right: 0px;
              top: 5px;
              text-decoration: underline;
              cursor: pointer;
              background-color: transparent;
              border: 0px;
            }
          }
          .multi-select-actions-wrap{
            display: flex;
            padding: 0 28px;
            .multi-select-actions-confirm{
              align-self: center;
              height: 50px;
              width: 328px;
              border-radius: 4px;
              background-color: #94C38E;
              border: 0px;
              color: #FFF;
              cursor: pointer;
            }
          }
          .order-state-filter-wrap{
            .order-state-filter{
              background-color: transparent;
              border: 0px;
              color: #57567C;
              font-size: 1rem;
              font-weight: bold;
              cursor: pointer;
              &:before{
                content: "";
                display: inline-block;
                position: relative;
                top: 7px;
                height: 25px;
                width: 25px;
                border-radius: 50%;
                margin-right: 7px;
              }
              &.en-transito{
                &:before{
                  border: 3px solid #FED514;
                }
                &.selected{
                  &:before{
                    background-color: #FED514;
                  }
                }
              }
              &.pendiente-clinic{
                &:before{
                  border: 3px solid #E37878;
                }
                &.selected{
                  &:before{
                    background-color: #E37878;
                  }
                }
              }
              &.pendiente-lab{
                &:before{
                  border: 3px solid #94C38E;
                }
                &.selected{
                  &:before{
                    background-color: #94C38E;
                  }
                }
              }
              &.completed{
                &:before{
                  border: 3px solid #57567C;
                }
                &.selected{
                  &:before{
                    background-color: #57567C;
                  }
                }
              }
            }
          }
          .search-filter-wrap{
            display: flex;
            justify-content: space-between;
            .search-wrap{
              align-self: center;
              width: 60%;
              .search-results{
              }
              .control{
                position: relative;
                margin: 0 auto;
                width: 100%;
                max-width: 640px;
                input{
                  position: relative;
                  z-index: 1;
                  height: 50px;
                  border: 1px solid grey;
                  &::placeholder {
                    color: #57567C;
                    opacity: 1; /* Firefox */
                  }

                  &::-ms-input-placeholder { /* Edge 12 -18 */
                    color: #57567C;
                  }
                }
                .icon{
                  height: 39px;
                  width: 39px;
                  margin-right: 3px;
                  margin-top: 5px;
                  background-color: #E3914F;
                  border-radius: 50%;
                  line-height: 39px;
                  color: #FFFFFF;
                  font-size: 1.4rem;
                }
                .is-right{
                  position: absolute;
                  top: 0px;
                  right: 1px;
                  cursor: pointer;
                  z-index: 9999999;
                  color: #FFF!important;
                }
              }
            }
            .filter-wrap{
              display: flex;
              height: 50px;
              justify-content: space-between;
              .order-wrap{
                display: flex;
                align-self: center;
                span{
                  display: inline-block;
                  height: 100%;
                  width: 25px;
                  font-size: 1.5rem;
                  margin-right: 7px;
                  opacity: .4;
                  align-self: center;
                  cursor: pointer;
                  &.selected{
                    opacity: 1;
                    color: #E3914F;
                  }
                }
              }
              button {
                background-color: transparent;
                border: 0px;
                display: flex;
                align-items: center;
                span{
                  font-size: 1.5rem;
                  &.text-underline{
                    text-decoration: underline;
                  }
                }
                &.close-pick-up{
                  color: #E3914F
                }
              }
            }
          }
          .order-group-wrap{
            width: 100%;
            margin-bottom: 28px;
            overflow: hidden;
            overflow-x: auto;
            .table-header{
              // width: 100%;
              display: flex;
              justify-content: space-between;
              height: 21px;
              margin-bottom: 16px;
              padding: 0px 10px;
              div{
                height: 100%;
                text-transform: uppercase;
                font-weight: bold;
              }
              .cell-small{
                width: 80px;
              }
              .cell-medium{
                width: 120px;
              }
              .cell-large{
                width: 170px;
              }
              .cell-large-percentage{
                width: calc(100% - 740px);
              }
            }
          }
          //  NAVIGATION
          .page-navigation-wrap{
            width: 100%;
            display: flex;
            justify-content: center;
            .page-navigation{
              button{
                width: 20px;
                display: flex;
                background-color: transparent;
                border: 0px;
                color: #57567C;
                justify-content: center;
                text-decoration: underline;
                font-size: 1.2rem;
                cursor: pointer;
                &.selected{
                  font-weight: bold;
                  text-decoration: none;
                }
              }
            }
          }
          .location-box{
            border-radius: 8px;
            padding: 21px 28px;
            &.is-empty{
              position: relative;
              background-color: transparent;
              border: 3px solid #F6F4EF;
              button{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 0px;
                margin: 0px;
                padding: 0px;
                background-color: transparent;
                cursor: pointer;
                &:hover{
                  span{
                    opacity: 1;
                  }
                }
                span{
                  opacity: .3;
                  font-size: 7rem;
                  transition: all .3s ease-out;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .dashboard-lab-orders{
    section{
      .my-account-wrap{
        .content-my-account-wrap{
          .right-column{
            .order-group-wrap{
              .column{
                padding-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .dashboard-lab-orders{
    section{
      .my-account-wrap{
        .content-my-account-wrap{
          .right-column{
            padding: 70px 40px;
          }
        }
      }
    }
  }
}
</style>
